import { defineComponent } from '@vue/composition-api';
export default defineComponent({
    props: {
        modelPropName: {
            type: String,
            default: ''
        },
        list: {
            type: Array,
            default: () => []
        },
        type: {
            type: String,
            default: ''
        },
        title: {
            type: [String, Object],
            default: ''
        },
        subTitle: {
            type: String,
            default: ''
        },
        coloredTitle: {
            type: Boolean,
            default: false
        }
    }
});
